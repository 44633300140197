<template>
    <div id="app" :class="isExchange? 'bootstrap-hva HVA exchange': 'bootstrap-hva HVA'">
        <div class="app-content">
            <section id="hva" class="max-width is-unselectable">
                <a v-if="$i18n.locale == 'nl'" href="https://www.hva.nl/">
                    <img class="hva-logo" alt="Hogeschool van Amsterdam" src="../src/images/logo.svg" />
                </a>
                <a v-else href="https://www.amsterdamuas.com/">
                    <img class="hva-logo" alt="Amsterdam University of Applied Sciences" src="../src/images/logo_amsterdam-uas.png" />
                </a>
            </section>
            <section class="max-width is-unselectable title-language">
                <h3 class="pageTitle">{{pageTitle}}</h3>
                <a v-show="loaded && !isExchange" class="language has-text-primary" :class="cooldownClass" @click="toggleLanguage">{{$i18n.locale == "en" ? "Nederlands" : "English"}}</a>
            </section>
            <section class="hero is-unselectable">
                <div class="hero-body is-paddingless">
                    <div class="banner-fade">
                        <div class="banner">
                            <nav class="max-width">
                                <div class="banner-item" v-for="(item, index) in navbarItems" :key="index">
                                    <router-link :class="item.css" v-if="index == 0" :to="{name: 'home'}">{{item.text}}</router-link>
                                    <a :class="item.css" v-else-if="index != 1 && index != 2" :href="item.href">{{item.text}}</a>
                                </div>
                            </nav>
                        </div>
                        <div class="banner-image mobile-hide">
                            <img class="banner-image-huisjes" v-if="isExchange" alt="Op de stoep van het Benno Premselahuis" src="../src/images/banner-huisjes.jpg">
                            <img v-else alt="Op de stoep van het Benno Premselahuis" src="../src/images/banner.jpg">
                        </div>
                    </div>
                </div>
            </section>
            <section id="view" class="max-width">
                <!-- whenever view changes, the :key changes, therefore the route will always refresh if from!=to -->
                <router-view :key="$route.fullPath" />
                <shareLinkModal v-show="showShareLinkModal" @close="close">
                </shareLinkModal>
            </section>
            <div class="iefooter"></div>
        </div>
        <div class="footer footerHvA" id="Footer" style="margin-top: 30px;">
            <span class="footer-copyright">&copy; 2023 {{$t("footer.hva")}}</span>
            <div class="footer-links">
                <span css="footer-disclaimer"><a href="/disclaimer">{{$t("footer.disclaimer")}}</a></span><span css="footer-privacy"><a href="/privacy">{{$t("footer.privacy")}}</a></span>
            </div>
        </div>
    </div>
</template>
<script>
    import shareLinkModal from "@/components/ShareLinkModal.vue";
    /*eslint-disable */
    export default {
        components: {
            shareLinkModal
        },
        data() {
            return {
                showShareLinkModal: false,
                user: null,
                loaded: false,
                pageTitle: ""
            };
        },
        methods: {
            getEditorShareUrl() {
                var editorLink = location.protocol + "/" + location.host + "/redactie" + location.pathname
                var editorInput = document.getElementById("editorLinkInput");
                editorInput.value = editorLink;
                this.showShareLinkModal = true;
            },
            close() {
                this.showShareLinkModal = false;
            },
            toggleLanguage() {
                var languageToSwitchTo = this.$i18n.locale == "en" ? "nl" : "en";
                if (languageToSwitchTo == "nl") {
                    if (this.isDev) {
                        location.href = "https://dev-studiegids.azurewebsites.net/"
                    } else if (this.isAcc) {
                        location.href = "https://acc-studiegids.mijnhva.nl/"
                    } else {
                        location.href = "https://studiegids.hva.nl/"
                    }
                } else {
                    if (this.isDev) {
                        location.href = "https://dev-coursecatalogue.mijnhva.nl/"
                    } else if (this.isAcc) {
                        location.href = "https://acc-coursecatalogue.mijnhva.nl/";
                    } else {
                        location.href = "https://coursecatalogue.amsterdamuas.com";
                    }
                }
            },
            async checkIfBrowserIsOutdated() {
                await this.$store.dispatch("getBrowserIsOutdated");
                var isBrowserOutdated = this.$store.state.isBrowserOutdated;
                if (isBrowserOutdated && this.$route.path != "/browseroutdated" && (this.userIsProgramEditor || this.userIsProgramFinalEditor || this.userIsCourseEditor || this.userIsCourseFinalEditor)) {
                    this.$router.push({ name: "browseroutdated"})
                }
            }
        },
        computed: {
            navbarItems() {
                var navItem = function (css, href, text) {
                    return { css, href, text };
                };
                if (this.$i18n.locale == "en") {
                    if (this.isExchange) {
                        return [
                            navItem("studiegids", "/", "Course Catalogue"),
                            navItem(),
                            navItem(),
                            navItem("mijnhva", "https://start.mijnhva.nl/", "MijnHvA"),
                            navItem("az-lijst", "https://student.amsterdamuas.com/a-z-exchange/az-exchange.html", "AZ List"),
                            navItem("exchange", "https://amsterdamuas.com/exchange", "Exchange"),
                            navItem("sis", "http://sis.hva.nl/", "SIS"),
                            navItem("webmail", "https://webmail.hva.nl/", "Webmail"),
                            navItem("roosters", "http://rooster.hva.nl/", "Timetable")
                        ];
                    } else {
                        return [
                            navItem("studiegids", "/", "Course Catalogue"),
                            navItem(),
                            navItem(),
                            navItem("mijnhva", "https://start.mijnhva.nl/", "MijnHvA"),
                            navItem("az-lijst", "https://student.amsterdamuas.com/a-z-generic/az.html", "AZ List"),
                            navItem("webcolleges", "http://www.hva.nl/webcolleges", "Web Lectures"),
                            navItem("sis", "http://sis.hva.nl/", "SIS"),
                            navItem("webmail", "https://webmail.hva.nl/", "Webmail"),
                            navItem("roosters", "http://rooster.hva.nl/", "Timetable")
                        ];
                    }

                } else {
                    return [
                        navItem("studiegids", "/", "Studiegids"),
                        navItem(),
                        navItem(),
                        navItem("mijnhva", "https://start.mijnhva.nl/", "MijnHvA"),
                        navItem("az-lijst", "https://student.hva.nl/studenten-algemeen/a-z.html", "AZ Lijst"),
                        navItem("webcolleges", "http://www.hva.nl/webcolleges", "Webcolleges"),
                        navItem("sis", "http://sis.hva.nl/", "SIS"),
                        navItem("webmail", "https://webmail.hva.nl/", "Webmail"),
                        navItem("roosters", "http://rooster.hva.nl/", "Roosters")
                    ];

                }
            },
            cooldownClass() {
                return this.cooldown ? "cooldown" : "";
            }
        },
        async created() {
            this.pageTitle = document.title;
            this.$store.commit("initializeStore");
            await this.$store.dispatch("getRoles");
            await this.checkIfBrowserIsOutdated();
            this.$store.dispatch("getAcademicYears");
            this.loaded = true;
        }
    };
</script>
<style lang="less" scoped>
    h3.pageTitle {
        padding-left: 30px;
        font-weight: normal;
        margin-bottom: 13px;
    }
    .max-width {
        max-width: 1024px;
        margin: 0px auto;
    }

    a.cooldown {
        color: #333 !important;
    }

    #app {
        position: relative;
        display: flex;
        min-height: 100vh;
        height: 100%;
        flex-direction: column;
        
        .app-content {
            flex: 1;
        }
    }

    .title-language {
        display: flex;
        justify-content: space-between;
    }

    .language {
        margin: 10px 18px 8px 8px;
        margin-bottom: unset;
        font-size: 16px;
        width: 100px;
        text-align: right;
        cursor: pointer;
    }

    #hva {
        padding: 20px 10px 20px 20px;
        display: flex;
        justify-content: space-between;
    }

    .hero {
        margin: 0px auto;
        min-height: 55px;
        max-height: 272px;
        overflow: hidden;

        .banner {
            z-index: 2;
            position: absolute;
            height: 55px;
            width: 100%;
            background-color: rgb(37, 22, 122);

            nav {
                display: grid;
                display: -ms-grid;
                grid-template-columns: repeat(2, minmax(20px, 55px)) 0px repeat(6, minmax(20px, 55px));
                -ms-grid-columns: 55px 55px 55px 55px 55px 55px 55px 55px 55px;
                grid-template-rows: 55px;
                 -ms-grid-rows: 55px;

                > :nth-child(1)
                {
                    -ms-grid-row: 1;
                    -ms-grid-column: 1;
                }

                > :nth-child(2)
                {
                    -ms-grid-row: 1;
                    -ms-grid-column: 2;
                }

                > :nth-child(3)
                {
                    -ms-grid-row: 1;
                    -ms-grid-column: 3;
                }

                > :nth-child(4)
                {
                    -ms-grid-row: 1;
                    -ms-grid-column: 4;
                }

                > :nth-child(5)
                {
                    -ms-grid-row: 1;
                    -ms-grid-column: 5;
                }

                > :nth-child(6)
                {
                    -ms-grid-row: 1;
                    -ms-grid-column: 6;
                }

                > :nth-child(7)
                {
                    -ms-grid-row: 1;
                    -ms-grid-column: 7;
                }

                > :nth-child(8)
                {
                    -ms-grid-row: 1;
                    -ms-grid-column: 8;
                }

                > :nth-child(9)
                {
                    -ms-grid-row: 1;
                    -ms-grid-column: 9;
                }
            }

            .banner-item {
                text-align: center;
                position: relative;

                @banner-icons: studiegids, roosters, webmail, sis, webcolleges, az-lijst, mijnhva, exchange;
                each(@banner-icons, {
                        .@{value} {
                            background-image: %(~"url(../src/images/banner-icons/%a.svg)", @value);
                        }
                    });
                a {
                    padding-top: 30px;
                    display: block;
                    text-transform: uppercase;
                    color: white;
                    overflow: hidden;
                    font-size: 7px;
                    font-weight: 600;
                    height: 50px;
                    min-width: 30px;
                    max-width: 85px;
                    background-repeat: no-repeat;
                    background-position: center 7px;
                    background-size: 25px auto;
                    line-height: 22px;
                    text-align: center;
                    opacity: 0.7;
                    
                    &:hover {
                        opacity: 1;
                    }

                }
            }
        }
    }

    #view {
        padding: 12px 12px;
        position: relative;
    }

    .footer {
        padding: 10px 25px;
    }

    //modifications for desktop
    @media only screen and (max-width: 767px) {
        .hero .banner nav {
            -ms-grid-columns: 55px 0 0 55px 55px 55px 55px 55px 55px;
        }
    }

    @media only screen and (min-width: 768px) {
        #hva {
            .language {
            font-size: initial;
            }
        }

        .hero {
            .banner {
                background-color: rgba(37, 22, 122, 0.7);
            
                nav
                {
                    grid-template-columns: repeat(2, minmax(55px, auto)) 1fr repeat(6, minmax(55px, auto));
                    grid-column-gap: 10px;
                    padding: 0px 10px;
                }

                .banner-item {
                    &:first-child::after {
                        content: " ";
                        position: absolute;
                        left: 4px;
                        bottom: -20px;
                        width: 46px;
                        height: 20px;
                        margin: 0;
                        z-index: 1;
                        background: url(../src/images/nav-arrow.svg) no-repeat 0 0;
                    }

                    a {
                        font-size: 8px;
                    }

                }
            }

            .banner-image {
                position: relative;
                overflow: hidden;
                display: flex;
                justify-content: center;
                
                img {
                    object-fit: cover;
                }

                &::after {
                    content: "";
                    background: url(../src/images/image-overlay-default.svg) no-repeat;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-size: 1312px;
                    background-position: center 0;
                    z-index: 1;
                }
            }
            .banner-fade{
                display: block;
                position: relative;
            }
            .banner-fade::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                background: url(../src/images/image-fade.svg) no-repeat;
                background-position: center 0;
                background-size: 1312px 382px;
            }
        }

        .max-width {
            max-width: 1024px;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>
<style type="text/css">

    span.footer-copyright { 
	float: left;
}
    /* Footer */
    .footer {
	padding: 0 25px;
	padding-top: 10px;
	padding-bottom: 10px;
	color: #666;
	float: left;
	width: 100%;
	background-color: #f2f2f2;
	box-sizing: border-box;
        font-family: OpenSans Regular,Segoe UI,Segoe,Tahoma,Helvetica,Arial,sans-serif;
    font-weight: 400;
    line-height: 1.5;
}
    .iefooter {
    display: none;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
             /* IE10+ CSS styles go here */
        .footer {
            bottom:0;
            position: fixed;
            z-index:50;
        }
         .iefooter {
             display:block;
            height: 44px;
    }
        
}

.footer-links {
	float: right;

}

.footer-links a {
	text-decoration: none;
	padding-left: 5px;
	color: #666;
}

.footer-links a:hover {
	text-decoration: underline;
}

.banner-image-huisjes{
    width: 1312px;
}
</style>
