import en from './en.json'
import nl from './nl.json'

export const translations = {
    'en': en,
    'nl': nl
}

export const table_translations = [
    [/Relatie met andere studieonderdelen/gi, "Associated courses"],
    [/Werkcoll\..?\/.?instructiecollege/gi, "Tutorial"],
    [/Relatie met beroepspraktijk/gi, "Professional context"],
    [/Ondergrens bij compensatie/gi, "minimum mark to qualify for compensation"],
    [/Paper.?\/.?verslag.?\/.?scriptie/gi, "Paper / Report / Thesis"],
    [/Leerdoelen.?\/.?competenties/gi, "Learning objectives / Competencies"],
    [/Studieloopbaanbegeleiding/gi, "Study counselling"],
    [/Begeleiding.?\/.?consultancy/gi, "Guidance / Consultancy"],
    [/Leerdoelen\/competenties/gi, "Learning objectives / Competencies"],
    [/Supervisie.?\/.?reflectie/gi, "Supervision / Reflection"],
    [/Taal van het onderwijs/gi, "Language of instruction"],
    [/Toelichting werkvormen/gi, "Additional notes"],
    [/Voldaan of Onvoldoende/gi, "Pass/Fail"],
    [/Cijfer met 1 decimaal/gi, "Mark to one decimal place"],
    [/Portfolioproducten/gi, "Portfolio products"],
    [/Toets schriftelijk/gi, "Test (written)"],
    [/Studiepunten in EC/gi, "Credits (EC, European Credits)"],
    [/Omschrijving SIS/gi, "Course name in SIS"],
    [/Beoordelingsmaat/gi, "assessment measure"],
    [/Stage.?\/.?praktijk/gi, "Internship"],
    [/Studiegidsnummer/gi, "Catalogue number"],
    [/Geen ondergrens/gi, "no minimum mark"],
    [/Zelfstudie-uren/gi, "Independent study hours"],
    [/Studiegids­nummer/gi, "Course catalogue number"],
    [/Studiemateriaal/gi, "Course materials"],
    [/Toets mondeling/gi, "Test (oral)"],
    [/Tijd en plaats/gi, "Schedule and location"],
    [/Toets praktijk/gi, "Test (practical)"],
    [/Toets digitaal/gi, "Test (digital)"],
    [/Geheel cijfer/gi, "Rounded mark"],
    [/Aanwezigheid/gi, "Attendance"],
    [/Beschrijving/gi, "Description"],
    [/Ingangseisen/gi, "Entry requirements"],
    [/Presentatie/gi, "Presentation"],
    [/Compensatie/gi, "compensation"],
    [/Toetsvormen/gi, "Assessment methods"],
    [/Collegejaar/gi, "Academic year"],
    [/Contacttijd/gi, "Contact time"],
    [/Coördinator/gi, "Course coordinator"],
    [/Contacturen/gi, "Contact hours"],
    [/Hoorcollege/gi, "Lecture"],
    [/Werkvormen/gi, "Teaching methods"],
    [/Assessment/gi, "Assessment"],
    [/Beg\.\/cons\./gi, "Guid/cons."],
    [/Studiejaar/gi, "Academic year"],
    [/Docent(en)/gi, "Lecturer(s)"],
    [/Toets schr/gi, "Test writ."],
    [/Supervisie/gi, "Supervisn."],
    [/Studlb\.beg/gi, "Counsel."],
    [/Presentat\./gi, "Presentat."],
    [/Toets prak/gi, "Test pract"],
    [/Toets mond/gi, "Test oral"],
    [/Studlb\.beg/gi, "Counsel."],
    [/Toets digi/gi, "Test digi."],
    [/Werkcoll\./gi, "Tutorial"],
    [/Practicum/gi, "PracticLab"],
    [/Opleiding/gi, "Programme"],
    [/Toetsvorm/gi, "Type"],
    [/Portfolio/gi, "Portfolio"],
    [/Presentie/gi, "Attendance"],
    [/Werkcoll\./gi, "Tutorial"],
    [/Practicum/gi, "PracticLab"],
    [/Leerlijn/gi, "Learning track"],
    [/Training/gi, "Training"],
    [/Opdracht/gi, "Assignment"],
    [/Excursie/gi, "Excursion"],
    [/Periode/gi, "Period"],
    [/College/gi, "Lecture"],
    [/Product/gi, "Product"],
    [/Project/gi, "Project"],
    [/Weging/gi, "%"],
    [/Paper/gi, "Paper"],
    [/Stage/gi, "Internship"],
    [/Naam/gi, "Name"],
    [/Nee/gi, "no"],
    [/Ja/gi, "yes"],
    [/Nr/gi, "No"]
];