/*eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import * as common from 'common/store/storeFunctions.js'

Vue.use(Vuex);

var host = location.host;
var language = 1043; // // set to 1033 for exchangeCourseCatalogue 
var exchangeProgram = "false";  // set to true for exchangeCourseCatalogue and comment the next statement
if (host.indexOf("exchange") != -1) {
    exchangeProgram = "true";
    language = 1033;
} else if (host.indexOf("coursecatalogue") != -1) {
    language = 1033;
}
var base = `${location.protocol}//${host}`;

export default new Vuex.Store({
    state: {
        project: null,
        programs: {},
        program: {},
        roles: {},
        rolesHaveBeenSet: false,
        lastUpdated: {},
        acYears: null,
        acYearsHaveBeenSet: false,
        programArgumentExeption: {},
        content: {},
        isBrowserOutdated: false,
    },
    getters: {
        isStudiegids: () => true,
        isCourseSelector: () => false,
        isOnderwijslijst: () => false
    },
    mutations: {
        async initializeStore(state) {
            common.initLocalForage()
            var url = `${base}/api/user/getuser`;
            var user = await common.get(url);
            var project = `${user.userName}.studiegids`;
            Vue.set(state, 'project', project);

            await common.getLocal(project).then(function (value) {
                Object.assign(state, value);
            });
        },
        newUpdate(state, key) {
            state.lastUpdated[key] = new Date().getTime();
        },
        resetCacheForKey(state, key) {
            state.lastUpdated[key] = undefined;
        },
        addPrograms(state, programs) {
            //master list programs
            state.programs = {
                ...state.programs,
                [programs.year]: programs.value
            };
            common.saveLocal(state);
        },
        setProgram(state, program) {
            //detailed programs
            var programObject = {
                ...state.program[program.id],
                details: program.value
            }
            state.program = {
                ...state.program,
                [program.id]: programObject
            }
            common.saveLocal(state);
        },
        setProgramExeption(state, error) {
            var errorObject = {
                ...state.programArgumentExeption[error.id],
                details: error.value
            }
            state.programArgumentExeption = {
                ...state.programArgumentExeption,
                [error.id]: errorObject
            }
            common.saveLocal(state);
        },
        setPhasing(state, phasing) {
            var programObject = {
                ...state.program[phasing.id],
                phasings: phasing.value
            }
            state.program = {
                ...state.program,
                [phasing.id]: programObject
            }
            common.saveLocal(state);
        },
        addCourse(state, course) {
            var courses = state.program[course.id] ? state.program[course.id].courses : {};
            var courseObject = {
                ...courses,
                [course.key]: course.value
            }
            var programObject = {
                ...state.program[course.id],
                courses: courseObject
            }
            state.program = {
                ...state.program,
                [course.id]: programObject
            }
            common.saveLocal(state);
        },
        addFutureEdit(state, futureItem) {
            var coursesFutureEdit = state.program[futureItem.id] ? state.program[futureItem.id].coursesFutureEdit : {};
            var futureEditObject = {
                ...coursesFutureEdit,
                [futureItem.key]: futureItem.value
            }
            var programObject = {
                ...state.program[futureItem.id],
                coursesFutureEdit: futureEditObject
            }
            state.program = {
                ...state.program,
                [futureItem.id]: programObject
            }
            common.saveLocal(state);
        },
        addBaseitems(state, baseitems) {
            var baseitemz = state.program[baseitems.id] ? state.program[baseitems.id].baseitems : {};
            var baseitemObject = {
                ...baseitemz,
                [baseitems.key]: baseitems.value
            }
            var programObject = {
                ...state.program[baseitems.id],
                baseitems: baseitemObject
            }
            state.program = {
                ...state.program,
                [baseitems.id]: programObject,
                lastUpdated: new Date().getTime()
            }
            common.saveLocal(state);
        },
        setRoles(state, roles) {
            state.roles = roles;
            state.rolesHaveBeenSet = true;
            common.saveLocal(state);
        },
        setContent(state, content) {
            var ContentObject = {
                ...state.content[content.id],
                details: content.value
            }
            state.content = {
                ...state.content,
                [content.id]: ContentObject
            }
            common.saveLocal(state);
        },
        setAcYears(state, acYears) {
            state.acYears = acYears;
            state.acYearsHaveBeenSet = true;
            common.saveLocal(state);
        },
        addAcYearsForProgram(state, acYearsForProgram) {
            var programObject = {
                ...state.program[acYearsForProgram.id],
                acYears: acYearsForProgram.value
            }
            state.program = {
                ...state.program,
                [acYearsForProgram.id]: programObject
            }
            common.saveLocal(state);
        },
        setProgramBaseItemToBeClonedTo(state, baseItem) {
            state.programBaseItemToBeClonedTo = baseItem;
        },
        setAutoSaveIdTo(state, autoSavesObject) {
            state.autoSaveItem = autoSavesObject.Item;
            state.autoSaveId = autoSavesObject.Id;
            state.autoSaveType = autoSavesObject.Type;
        },
        setBrowserIsOutdated(state, isBrowserOutdated) {
            state.isBrowserOutdated = isBrowserOutdated;
            common.saveLocal(state);
        },
        setDeepLink(state, deeplink) {
            state.deeplink = deeplink;
            common.saveLocal(state);
        }
    },
    actions: {
        async getAcademicYears(context) {
            var isEditor = false;
            if (!context.state.rolesHaveBeenSet) {
                await context.dispatch("getRoles");
            }
            if (context.state.roles) {
                if (exchangeProgram == "true") {
                    isEditor = (context.state.roles.ExchangeProgramProgramEditor && context.state.roles.ExchangeProgramProgramEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramProgramFinalEditor && context.state.roles.ExchangeProgramProgramFinalEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramCourseEditor && context.state.roles.ExchangeProgramCourseEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramCourseFinalEditor && context.state.roles.ExchangeProgramCourseFinalEditor.length > 0);
                } else {
                    isEditor = (context.state.roles.CourseCatalogProgramEditor && context.state.roles.CourseCatalogProgramEditor.length > 0) ||
                        (context.state.roles.CourseCatalogProgramFinalEditor && context.state.roles.CourseCatalogProgramFinalEditor.length > 0) ||
                        (context.state.roles.CourseCatalogCourseEditor && context.state.roles.CourseCatalogCourseEditor.length > 0) ||
                        (context.state.roles.CourseCatalogCourseFinalEditor && context.state.roles.CourseCatalogCourseFinalEditor.length > 0);
                }
            }
            var url = `${base}/api/program/getacademicyears/${isEditor}/${exchangeProgram}`;
            var acYears = await common.get(url);
            context.commit("setAcYears", acYears);
        },
        async getPrograms(context, currentYear) {
            if (!currentYear) {
                if (!context.state.acYearsHaveBeenSet) {
                    await context.dispatch("getAcademicYears");
                }
                currentYear = context.state.acYears.currentAcademicYear;
            }
            var updateKey = `programs.${currentYear}`;

            if (!common.refreshCache(context.state, updateKey))
                return;

            var sig = "/api/program/getprogramsbyacademicyears";
            if (!context.state.rolesHaveBeenSet) {
                await context.dispatch("getRoles");
            }
            var isEditor = false;
            if (context.state.roles) {
                if (exchangeProgram == "true") {
                    isEditor = (context.state.roles.ExchangeProgramProgramEditor && context.state.roles.ExchangeProgramProgramEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramProgramFinalEditor && context.state.roles.ExchangeProgramProgramFinalEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramCourseEditor && context.state.roles.ExchangeProgramCourseEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramCourseFinalEditor && context.state.roles.ExchangeProgramCourseFinalEditor.length > 0);
                } else {
                    isEditor = (context.state.roles.CourseCatalogProgramEditor && context.state.roles.CourseCatalogProgramEditor.length > 0) ||
                        (context.state.roles.CourseCatalogProgramFinalEditor && context.state.roles.CourseCatalogProgramFinalEditor.length > 0) ||
                        (context.state.roles.CourseCatalogCourseEditor && context.state.roles.CourseCatalogCourseEditor.length > 0) ||
                        (context.state.roles.CourseCatalogCourseFinalEditor && context.state.roles.CourseCatalogCourseFinalEditor.length > 0);
                }
            }
            if (isEditor == undefined) { isEditor = false; }
            var url = base + [sig, currentYear, language, true, false, exchangeProgram, isEditor.toString()].join('/');

            var programs = await common.get(url);
            if (common.isNullOrUndefined(programs)) { return; }

            context.commit('addPrograms', { year: currentYear, value: programs });
            context.commit('newUpdate', updateKey);
        },
        async getProgram(context, params) {
            var programKey = params.programId ? params.programId : params.programSlug;
            if (!context.state.acYearsHaveBeenSet) {
                await context.dispatch("getAcademicYears");
            }
            var acYear = context.state.acYears.currentAcademicYear;
            if (params.acYear) {
                acYear = params.acYear;
            }
            var key = acYear + programKey;
            var updateKey = `program.${key}.details`;
            if (!context.state.rolesHaveBeenSet) {
                await context.dispatch("getRoles");
            }
            var isEditor = false;
            if (context.state.roles) {
                if (exchangeProgram == "true") {
                    isEditor = (context.state.roles.ExchangeProgramProgramEditor && context.state.roles.ExchangeProgramProgramEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramProgramFinalEditor && context.state.roles.ExchangeProgramProgramFinalEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramCourseEditor && context.state.roles.ExchangeProgramCourseEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramCourseFinalEditor && context.state.roles.ExchangeProgramCourseFinalEditor.length > 0);
                } else {
                    isEditor = (context.state.roles.CourseCatalogProgramEditor && context.state.roles.CourseCatalogProgramEditor.length > 0) ||
                        (context.state.roles.CourseCatalogProgramFinalEditor && context.state.roles.CourseCatalogProgramFinalEditor.length > 0) ||
                        (context.state.roles.CourseCatalogCourseEditor && context.state.roles.CourseCatalogCourseEditor.length > 0) ||
                        (context.state.roles.CourseCatalogCourseFinalEditor && context.state.roles.CourseCatalogCourseFinalEditor.length > 0);
                }
            }
            var useCache = !isEditor;
            var forceCache = false;
            if (params.forceCache) {
                forceCache = params.forceCache;
            }
            if ((useCache || forceCache) && !common.refreshCache(context.state, updateKey))
                return;

            var sig, url;
            var slug = params.programSlug;
            sig = "/api/program/GetBySlug";

            url = base + [sig, slug, acYear, language, exchangeProgram, useCache].join('/');
            try {
                var program = await common.get(url);
            } catch (error) {
                context.commit('setProgramExeption', { id: key, value: error })
            }

            if (common.isNullOrUndefined(program)) {
                return;
            }
            context.commit('setProgram', { id: key, value: program })
            context.commit('newUpdate', updateKey);
        },
        async getPhasing(context, params) {
            var programKey = params.programId ? params.programId : params.programSlug;
            if (!context.state.acYearsHaveBeenSet) {
                await context.dispatch("getAcademicYears");
            }
            var acYear = context.state.acYears.currentAcademicYear;
            if (params.acYear) {
                acYear = params.acYear;
            }
            if (!context.state.rolesHaveBeenSet) {
                await context.dispatch("getRoles");
            }
            var key = acYear + programKey;
            var updateKey = `program.${key}.phasings`;
            var isEditor = false;
            if (context.state.roles) {
                if (exchangeProgram == "true") {
                    isEditor = (context.state.roles.ExchangeProgramProgramEditor && context.state.roles.ExchangeProgramProgramEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramProgramFinalEditor && context.state.roles.ExchangeProgramProgramFinalEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramCourseEditor && context.state.roles.ExchangeProgramCourseEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramCourseFinalEditor && context.state.roles.ExchangeProgramCourseFinalEditor.length > 0);
                } else {
                    isEditor = (context.state.roles.CourseCatalogProgramEditor && context.state.roles.CourseCatalogProgramEditor.length > 0) ||
                        (context.state.roles.CourseCatalogProgramFinalEditor && context.state.roles.CourseCatalogProgramFinalEditor.length > 0) ||
                        (context.state.roles.CourseCatalogCourseEditor && context.state.roles.CourseCatalogCourseEditor.length > 0) ||
                        (context.state.roles.CourseCatalogCourseFinalEditor && context.state.roles.CourseCatalogCourseFinalEditor.length > 0);
                }
            }
            var useCache = !isEditor;
            var forceCache = false;
            if (params.forceCache) {
                forceCache = params.forceCache;
            }
            if ((useCache || forceCache) && !common.refreshCache(context.state, updateKey))
                return;

            var sig, url;
            var slug = params.programSlug;
            sig = "/api/phasinginfo/GetByProgramSlug";
            url = base + [sig, slug, acYear, language, exchangeProgram, useCache].join('/');

            var phasing = await common.get(url);
            if (common.isNullOrUndefined(phasing)) { return; }

            context.commit('setPhasing', { id: key, value: phasing })
            context.commit('newUpdate', updateKey);
        },
        async getFutureEdit(context, params) {
            var programKey = params.programId ? params.programId : params.programSlug;
            var url = base + "/api/coursecatalog/getfutureedit/" + params.futureEditCourseCatalogId + "/true";
            var courseFutureEdit = await common.get(url);
            if (common.isNullOrUndefined(courseFutureEdit)) { return; }
            var acYear = context.state.acYears.currentAcademicYear;
            if (params.acYear) {
                acYear = params.acYear;
            }
            var key = acYear + programKey;
            var updateKey = `program.${key}.coursesFutureEdit.${params.baseItemId}`;
            var courseFutureEditWithBaseItem = JSON.parse(JSON.stringify(params.baseItem));
            courseFutureEditWithBaseItem.CourseCatalogItem = courseFutureEdit;
            context.commit('addFutureEdit', { id: key, key: params.baseItemId, value: courseFutureEditWithBaseItem })
            context.commit('newUpdate', updateKey);
        },
        async getCourse(context, params) {
            var programKey = params.programId ? params.programId : params.programSlug;
            if (!context.state.acYearsHaveBeenSet) {
                await context.dispatch("getAcademicYears");
            }
            var acYear = context.state.acYears.currentAcademicYear;
            if (params.acYear) {
                acYear = params.acYear;
            }
            var key = acYear + programKey;
            var updateKey = `program.${key}.courses.${params.baseItemId}`;
            var useCache = !((exchangeProgram == "true" && (context.state.roles.ExchangeProgramCourseFinalEditor && context.state.roles.ExchangeProgramCourseFinalEditor.length > 0) ||
                (context.state.roles.ExchangeProgramCourseEditor && context.state.roles.ExchangeProgramCourseEditor.length > 0)) ||
                (exchangeProgram == "false" && (context.state.roles.CourseCatalogCourseFinalEditor && context.state.roles.CourseCatalogCourseFinalEditor.length > 0) ||
                    (context.state.roles.CourseCatalogCourseEditor && context.state.roles.CourseCatalogCourseEditor.length > 0)));
            var forceCache = false;
            if (params.forceCache) {
                forceCache = params.forceCache;
            }
            if ((useCache || forceCache) && !common.refreshCache(context.state, updateKey))
                return;

            var sig, url;
            if (!context.state.rolesHaveBeenSet) {
                await context.dispatch("getRoles");
            }

            var id = "BSI-" + params.baseItemId;
            sig = "/api/baseitem/GetByBaseitemId";
            url = base + [sig, id, language, exchangeProgram, useCache].join('/');

            var course = await common.get(url);
            if (common.isNullOrUndefined(course)) { return; }

            context.commit('addCourse', { id: key, key: params.baseItemId, value: course })
            context.commit('newUpdate', updateKey);
        },
        async getBaseitems(context, params) {
            var pvalue = params.phaseValue;
            var programKey = params.programId ? params.programId : params.programSlug;
            if (!context.state.acYearsHaveBeenSet) {
                await context.dispatch("getAcademicYears");
            }
            var acYear = context.state.acYears.currentAcademicYear;
            if (params.acYear) {
                acYear = params.acYear;
            }
            var key = acYear + programKey;
            var sig, url;

            var slug = params.programSlug;
            sig = "/api/baseitem/GetByProgramSlugAndPhasing";
            if (!context.state.rolesHaveBeenSet) {
                await context.dispatch("getRoles");
            }
            var updateKey = `program.${key}.baseitems.${pvalue}`;
            var useCache = !((exchangeProgram == "true" && (context.state.roles.ExchangeProgramCourseFinalEditor && context.state.roles.ExchangeProgramCourseFinalEditor.length > 0) ||
                (context.state.roles.ExchangeProgramCourseEditor && context.state.roles.ExchangeProgramCourseEditor.length > 0)) ||
                (exchangeProgram == "false" && (context.state.roles.CourseCatalogCourseFinalEditor && context.state.roles.CourseCatalogCourseFinalEditor.length > 0) ||
                    (context.state.roles.CourseCatalogCourseEditor && context.state.roles.CourseCatalogCourseEditor.length > 0)));
            url = base + [sig, slug, acYear, pvalue, language, exchangeProgram, useCache].join('/');

            if (useCache && !common.refreshCache(context.state, updateKey))
                return;

            var baseItems = await common.get(url);
            if (common.isNullOrUndefined(baseItems)) { return; }

            var details = context.state.program[key].details;
            baseItems = common.sortBaseitems(baseItems, { sort: details.SortPhasingAndGroupings, manual: details.ManualSorting })

            context.commit('addBaseitems', { id: key, key: pvalue, value: baseItems });
            context.commit('newUpdate', updateKey);
        },
        async getContent(context, param) {
            var updateKey = `content.${param}.${language}`;
            if (!common.refreshCache(context.state, updateKey)) {
                return;
            }

            var sig = "/api/content/Get";
            var url = base + [sig, param, language].join('/');

            var content = await common.get(url);
            if (common.isNullOrUndefined(content)) { return; }

            context.commit('setContent', { id: param, value: content })
            context.commit('newUpdate', updateKey);
        },
        async getRoles(context) {
            var url = `${base}/api/user/getRoles`;
            var roles = await common.get(url);
            context.commit("setRoles", roles);
        },
        async getAcYearsForProgram(context, params) {
            var updateKey = `program.${params.programSlug}.acYears`;
            if (!common.refreshCache(context.state, updateKey))
                return;
            var sig = "/api/program/GetAcademicYearsForProgram";
            if (!context.state.rolesHaveBeenSet) {
                await context.dispatch("getRoles");
            }
            var isEditor = false;
            if (context.state.roles) {
                if (exchangeProgram == "true") {
                    isEditor = (context.state.roles.ExchangeProgramProgramEditor && context.state.roles.ExchangeProgramProgramEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramProgramFinalEditor && context.state.roles.ExchangeProgramProgramFinalEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramCourseEditor && context.state.roles.ExchangeProgramCourseEditor.length > 0) ||
                        (context.state.roles.ExchangeProgramCourseFinalEditor && context.state.roles.ExchangeProgramCourseFinalEditor.length > 0);
                } else {
                    isEditor = (context.state.roles.CourseCatalogProgramEditor && context.state.roles.CourseCatalogProgramEditor.length > 0) ||
                        (context.state.roles.CourseCatalogProgramFinalEditor && context.state.roles.CourseCatalogProgramFinalEditor.length > 0) ||
                        (context.state.roles.CourseCatalogCourseEditor && context.state.roles.CourseCatalogCourseEditor.length > 0) ||
                        (context.state.roles.CourseCatalogCourseFinalEditor && context.state.roles.CourseCatalogCourseFinalEditor.length > 0);
                }
            }
            var useCache = !isEditor;
            var url = base + [sig, params.programSlug, exchangeProgram, useCache].join('/');
            var acYearsForProgram = await common.get(url);
            if (common.isNullOrUndefined(acYearsForProgram)) { return; }

            context.commit('addAcYearsForProgram', { id: params.programSlug, value: acYearsForProgram });
            context.commit('newUpdate', updateKey);

        },
        async getBrowserIsOutdated(context) {
            var url = "/api/GetBrowserIsOutdated";
            var outdatedBrowser = await common.get(url);
            context.commit("setBrowserIsOutdated", outdatedBrowser);
        },
        async postImageToBlob(context, payload) {
            var url = `${base}/api/blob/UploadBase64Image/${payload.name}/banner`;
            var uploadResponse = await common.post(url, payload.image);
            var result = uploadResponse.json();
            return result;
        },
    }
})