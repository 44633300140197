/*eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: "history",
    routes: [
        {
            name: 'home',
            path: '/',
            component: () =>
                import('./views/Home.vue')
        },
        {
            name: 'homeDeepLinked',
            path: '/programlist/:deepLink',
            component: () =>
                import('./views/Home.vue'),

        },
        {
            name: 'clone',
            path: '/clone',
            component: () => import('./views/Clone.vue'),
            beforeEnter(to, from, next) {
                if (from.name !== 'course-overview') {
                    next({ path: "/", replace: true });
                } else {
                    next();
                }

            }
        },
        {
            name: 'autosaves',
            path: '/autosaves',
            component: () => import('./views/Autosaves.vue'),
            beforeEnter(to, from, next) {
                if (from.name !== 'course-overview' && from.name !== 'program-overview') {
                    next({ path: "/", replace: true });
                } else {
                    next();
                }
            }
        },
        {
            name: 'archive',
            path: '/archief',
            component: () =>
                import('./views/Archive.vue')
        },
        {
            name: 'intellectueeleigendom',
            path: '/intellectueeleigendom',
            component: () =>
                import('./views/IP.vue')
        },
        {
            name: 'disclaimer',
            path: '/Disclaimer',
            component: () =>
                import('./views/Disclaimer.vue')
        },
        {
            name: 'honours',
            path: '/honours',
            component: () =>
                import('./views/Honours.vue')
        },
        {
            name: 'minoren',
            path: '/minoren',
            component: () =>
                import('./views/Minoren.vue')
        },
        {
            name: 'privacy',
            path: '/privacy',
            component: () =>
                import('./views/Privacy.vue')
        },
        {
            name: 'browseroutdated',
            path: '/browseroutdated',
            component: () =>
                import('./views/BrowserOutdated.vue')
        },
        {
            name: 'print-program-courses',
            path: '/print-program-courses/:programSlug/:acYear',
            component: () =>
                import('./views/Print.vue')
        },
        {
            name: 'print-program',
            path: '/print-program/:programSlug/:acYear',
            component: () =>
                import('./views/Print.vue')
        },
        {
            name: 'print-phasing',
            path: '/print-phasing/:programSlug/:phaseValue/:acYear',
            component: () =>
                import('./views/PrintPhasing.vue')
        },
        {
            name: 'print-course',
            path: '/print-program/:programSlug/:acYear/:baseItemId',
            component: () =>
                import('./views/PrintCourse.vue')
        },
        {
            name: 'program-overview',
            path: '/:programSlug/:acYear?',
            component: () =>
                import('./views/ProgramOverview.vue')
        },
        {
            //from sidebar
            name: 'phasing-overview',
            path: '/po/:programSlug/:acYear?/:phaseValue',
            component: () =>
                import('./views/PhasingOverview.vue')
        },
        {
            //from phasing overview
            name: 'course-overview',
            path: '/co/:programSlug/:phaseValue/:baseItemId/:acYear?',
            component: () =>
                import('./views/CourseOverview.vue')
        }
    ]
})