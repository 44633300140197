/*eslint-disable */
import settings from './../assets/settings.json'
import localforage from 'localforage';

export function initLocalForage() {
  localforage.config({
    driver: localforage.IndexedDB,
    name: 'COSMOS',
    version: 1.0,
    storeName: 'COSMOS-VUE'
  });
}

export function stringIsNullOrEmpty(s) {
    if (s !== undefined && s !== null && s !== "") {
        return false;
    }
    return true;
}

export function isNullOrUndefined(object) {
    return object === undefined || object === null
}

export function saveLocal(state) {
    localforage.setItem(state.project, state);
}

export function getLocal(project) {
  var data = localforage.getItem(project, function () { });
  return data
}

export function getAjaxErrorMessage(ajaxResult) {
    var message;
    if (ajaxResult.Message !== undefined)
        message = ajaxResult.Message;
    else if (ajaxResult.error !== undefined) {
        message = ajaxResult.error.message.value;
    } else {
        message = "An unspecified error occurred"
    }
    return message;
}

export function refreshCache(state, key) {
    if (state.lastUpdated === undefined || state.lastUpdated[key] === undefined) {
        return true;
    }
    var cacheInMinutes = settings.cacheAgeInMinutes[state.project];
    if (!cacheInMinutes){
        cacheInMinutes = 60;
    }
    var expirationTime = new Date(state.lastUpdated[key] + cacheInMinutes * 60000);
    return new Date().getTime() > expirationTime
}

export function refreshCacheFromMin(state, key, minutes){
    if (state.lastUpdated === undefined || state.lastUpdated[key] === undefined) {
        return true;
    }
    var expirationTime = new Date(state.lastUpdated[key] + minutes * 60000);
    return new Date().getTime() > expirationTime
}

function oneSuccessPromise(promises){
    return Promise.all(promises.map(p => {
        // If a request fails, count that as a resolution so it will keep
        // waiting for other possible successes. If a request succeeds,
        // treat it as a rejection so Promise.all immediately bails out.
        return p.then(
          val => Promise.reject(val),
          err => Promise.resolve(err)
        );
      })).then(
        // If '.all' resolved, we've just got an array of errors.
        errors => Promise.reject(errors),
        // If '.all' rejected, we've got the result we wanted.
        val => Promise.resolve(val)
      );
}

export async function get(url) {
    var recursiveGet = (trycount) => {
        return new Promise(async (resolve, reject) => {
            var trysuffix = trycount > 0 ? "?try=" + trycount : "";
            try {
                var response = await fetch(url + trysuffix, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json; odata=verbose'
                    },
                    dataType: 'json',
                    credentials: 'same-origin'
                })
                var result = await response.json();

                if (response.status !== 200) {
                    throw new Error(result.Message !== undefined ?
                        result.Message : result.error !== undefined ?
                            result.error.message.value : "An unspecified error occurred");
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        })
    };
    var wait;
    var wait2;
    var wait3;
    let promiseA = new Promise((resolve, reject) => {
         wait = setTimeout(async() => {
          let r = await recursiveGet(0).catch((e) => {
                clearTimeout(wait);
                reject(e);
            });
          clearTimeout(wait);
          resolve(r);
        }, 0)
      });
      let promiseB = new Promise((resolve, reject) => {
         wait2 = setTimeout(async() => {
            let r = await recursiveGet(1).catch((e) => {
                  clearTimeout(wait2);
                  reject(e);
              });
            clearTimeout(wait2);
            resolve(r);
        }, 6000)
      });
      let promiseC = new Promise((resolve, reject) => {
         wait3 = setTimeout(async() => {
            let r = await recursiveGet(2).catch((e) => {
                  clearTimeout(wait3);
                  reject(e);
              });
            clearTimeout(wait3);
            resolve(r);
        }, 12000)
      });

      var x = await oneSuccessPromise([promiseA, promiseB, promiseC]);
      clearTimeout(wait);
      clearTimeout(wait2);
      clearTimeout(wait3);
      return x;
}

export function put(url, data) {
    return new Promise(async (resolve, reject) => {
        try {
            var response = await fetch(url, {
                method: "PUT",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json; odata=verbose"
                },
                credentials: 'same-origin'
            });

            if (response.status !== 200) {
                var result = await response.json();
                throw new Error(result.Message !== undefined ?
                    result.Message : result.error !== undefined ?
                        result.error.message.value : "An unspecified error occurred");
            }
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export function post(url, data) {
    return new Promise(async (resolve, reject) => {
        try {
            var response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json; odata=verbose"
                },
                credentials: 'same-origin'
            });

            if (response.status !== 200) {
                var result = await response.json();
                throw new Error(result.Message !== undefined ?
                    result.Message : result.error !== undefined ?
                        result.error.message.value : "An unspecified error occurred");
            }
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}


// Redactieomgeving + Programlist actions
export function sortBaseitems(baseitems, sorting) {
    //sorting expects { sort: .., manual: ..}
    baseitems.sort(function (a, b) {
        if (a.SortId === null && b.SortId !== null) return 1;
        else if (a.SortId !== null && b.SortId === null) return -1;
        if (a.SortId == b.SortId) {
            return a.Title.toLowerCase() < b.Title.toLowerCase() ? -1 : a.Title.toLowerCase() > b.Title.toLowerCase() ? 1 : 0
        } else {
            return a.SortId > b.SortId ? 1 : -1;
        }
    });
    //put into groupings
    var groupings = {}
    baseitems.forEach(function (baseitem) {
        if (baseitem.Groupings == null) {
            console.log(baseitem, " has no Groupings and is ignored")
            return;
        }
        baseitem.Groupings.forEach(function (group) {
            var id = group.Id;
            if (!(id in groupings)) {
                groupings[id] = {
                    Id: id,
                    Title: group.Title,
                    Baseitems: []
                };
            }
            groupings[id].Baseitems.push(baseitem);
        })
    })
    var sortedGroups = [];
    if (sorting.manual && sorting.sort) {
        var sort = JSON.parse(sorting.sort);
        for (var g in sort.studiegids.groupings) {
            var grouping = groupings[sort.studiegids.groupings[g]];
            if (grouping) {
                sortedGroups.push(groupings[sort.studiegids.groupings[g]]);
            }
        }
    } else {
        for (var group in groupings) {
            sortedGroups.push(groupings[group]);
        }
        sortedGroups.sort(function (a, b) {
            return a.Title.toLowerCase() < b.Title.toLowerCase() ? -1 : a.Title.toLowerCase() > b.Title.toLowerCase() ? 1 : 0
        })
    }
    return sortedGroups;
}
export function camelCase(str) {	
    var result = str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index){
        return index == 0 ? word.toLowerCase() : word.toUpperCase()
    });
    return result.replace(/\s+/g, '');
}