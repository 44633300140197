if (!window.Promise || !window.fetch) {
    require("isomorphic-fetch");
}
import Vue from 'vue'
import Vuex from 'vuex'
import { mapState } from "vuex";
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import common from 'common'
import { translations } from './assets/translations'
import VueI18n from 'vue-i18n'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCopy, faChevronDown, faChevronUp, faTimes, faSync, faCaretRight, faCaretLeft, faPenSquare, faCircle, faChartBar, faExclamationTriangle, faDownload, faSearch, faLink, faExclamationCircle, faClone, faTimesCircle, faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight, faPlus, faPrint, faFilePdf, faFileExcel, faRecycle, faUserEdit, faReply, faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import store from "./store/store.js"
import jquery from 'jquery'
import { page } from 'vue-analytics'

import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import 'froala-editor/js/languages/nl.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/css/plugins/video.min.css'
import 'froala-editor/js/plugins/line_breaker.min.js'
import 'froala-editor/css/plugins/line_breaker.min.css'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/special_characters.min.js'
import 'froala-editor/css/plugins/special_characters.min.css'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/js/plugins/draggable.min.js'
import 'froala-editor/css/plugins/draggable.min.css'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/js/plugins/word_paste.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/css/plugins/fullscreen.min.css'
import 'froala-editor/js/plugins/print.min.js'
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/help.min.js'
import 'froala-editor/css/plugins/help.min.css'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/css/plugins/file.min.css'

library.add(faCopy)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faTimes)
library.add(faSync)
library.add(faCaretRight)
library.add(faCaretLeft)
library.add(faPenSquare)
library.add(faCircle)
library.add(faChartBar)
library.add(faExclamationTriangle)
library.add(faDownload)
library.add(faSearch)
library.add(faLink)
library.add(faExclamationCircle)
library.add(faClone)
library.add(faTimesCircle)
library.add(faAngleDoubleLeft)
library.add(faAngleLeft)
library.add(faAngleRight)
library.add(faAngleDoubleRight)
library.add(faPlus)
library.add(faPrint)
library.add(faFilePdf)
library.add(faFileExcel)
library.add(faRecycle)
library.add(faUserEdit)
library.add(faReply)
library.add(faShareAlt)

import './assets/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'common/assets/site.css'
import './assets/site.css' //Make sure this is imported last.

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

Vue.config.productionTip = false

Vue.component(VueCropper);
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Vuex)
Vue.use(BootstrapVue);
Vue.use(VueI18n);


var merge = require('lodash.merge');
const messages = merge(messages, common.translations, translations); //last object overwrites earlier objects
var host = location.host;
var locale = 'nl'; // set to 'en' for exchangeCourseCatalogue
var fallbackLocale = 'en';
var language = 1043;
var siteTitle = "Studiegids";
if (host.indexOf("exchange") != -1) {
	siteTitle = "Exchange Course Catalogue";
	locale = 'en';
	fallbackLocale = 'nl';
	language = 1033;
}
else if (host.indexOf("coursecatalogue") != -1) {
	siteTitle = "Course Catalogue";
	locale = 'en';
	fallbackLocale = 'nl';
	language = 1033;
}
document.title = siteTitle;
const i18n = new VueI18n({
  locale: locale,
	fallbackLocale: fallbackLocale,
  messages,
})

router.afterEach((to) => {
	page(to.fullPath);
});



//global mixin
Vue.mixin({
    data: function() {
        return {
			language: language 
        };
    },
	computed: {
		isExchange() {
			return location.host.indexOf("exchange") != -1; // set to true; for exchangeCourseCatalogue
		},
		isDev() {
			return location.host.indexOf("dev-") != -1;
		},
		isAcc() {
			return location.host.indexOf("acc-") != -1;
		},
        userIsProgramEditor(){
			if (this.roles) {
				if (this.isExchange && this.roles.ExchangeProgramProgramEditor && this.roles.ExchangeProgramProgramEditor.length > 0) {
					return true;
				}
				if (!this.isExchange && this.roles.CourseCatalogProgramEditor && this.roles.CourseCatalogProgramEditor.length > 0){
					return true;
				}
			}
			return false;
		},
		userIsProgramFinalEditor(){
			if (this.roles) {
				if (this.isExchange && this.roles.ExchangeProgramProgramFinalEditor && this.roles.ExchangeProgramProgramFinalEditor.length > 0) {
					return true;
				}
				if (!this.isExchange && this.roles.CourseCatalogProgramFinalEditor && this.roles.CourseCatalogProgramFinalEditor.length > 0){
					return true;
				}
			}
			return false;
        },
        userIsCourseEditor(){
			if (this.roles) {
				if (this.isExchange && this.roles.ExchangeProgramCourseEditor && this.roles.ExchangeProgramCourseEditor.length > 0) {
					return true;
				}
				if (!this.isExchange && this.roles.CourseCatalogCourseEditor && this.roles.CourseCatalogCourseEditor.length > 0){
					return true;
				}
			}
			return false;
		},
		userIsCourseFinalEditor(){
			if (this.roles) {
				if (this.isExchange && this.roles.ExchangeProgramCourseFinalEditor && this.roles.ExchangeProgramCourseFinalEditor.length > 0) {
					return true;
				}
				if (!this.isExchange && this.roles.CourseCatalogCourseFinalEditor && this.roles.CourseCatalogCourseFinalEditor.length > 0){
					return true;
				}
			}
			return false;
        },
		...mapState(["roles", "acYears"])
    },
	methods: {
		
        stringIsNullOrEmpty: function(string) {
            if (string !== undefined && string !== null && string !== "") {
                return false;
            }
            return true;
        },
        objectIsNull: function(object) {
            if (object !== undefined && object !== null) {
                return false;
            }
            return true;
        },
        isEmptyWithoutHtml: function(object) {
            if (object === undefined || object === null) {
                return true;
            } else if (object === "" || object.replace(/<(?:.|\n)*?>/gm, '').trim() === "") {
                return true;
            }
            return false;
        }
    }
})

Vue.prototype.$event = new Vue()
Vue.prototype.$ = jquery;

new Vue({
    i18n,
    router,
    store,
    beforeCreate() {
        this.$store.commit('initializeStore');
    },
    render: h => h(App)
}).$mount('#app')
